.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: #EEEEEE;
}

p {
  margin-top : 0px;
  margin-bottom : 0px
}

sidenav {
  position : fixed !important;
  overflow-y : auto !important;
}

.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
}

.star-rating-css {
  font-size:  36px;
}

.close-button-circle-css:focus {
  outline : none !important
}

.carousel ul.thumbs {
  display:  none;
}

div.carousel-root .carousel .control-prev.control-arrow:before {
  border-right : 8px solid #000;
}

div.carousel-root .carousel .control-prev.control-arrow, div.carousel-root .carousel .control-next.control-arrow {
  z-index : 0
}

div.carousel-root .carousel .control-next.control-arrow:before {
  border-left : 8px solid #000;
}

.accordion__button {
  width : auto !important;
}

.accordion {
  width: 100%;
}

ul.pagination {
  display: inline-block !important;
  border : 1px solid gray;
  border-radius: 4px;
  padding-inline-start: 0px;
}

ul.pagination li {
  display: inline-block !important;
  cursor: pointer;
  padding-top : 16px;
  padding-bottom : 16px;
}

ul.pagination li a {
  padding : 16px;
}

ul li.active {
  background-color: #dad5f6;
}

ul li.disabled {
  cursor : not-allowed
}

ul.pagination li:hover:not(.disabled) {
  background-color: #dad5f6;
  transition: ease 0.5s all;
  cursor: pointer;
}
.discount-container {
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: #109c27;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 30px;
  top: 60px;
  text-align: center;
  font-size: 28px;
  line-height: 1;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
}

.discount-container span {
  font-size: 14px;
}

.discount-container-md {
  right: 3px;
}

.smartpi-promo-popup .swal2-popup {
  padding: 0;
}

.swal2-container.smartpi-promo-popup .swal2-image {
  margin-top: 0;
}

.swal2-container.smartpi-promo-popup #swal2-content.swal2-html-container {
  padding: 15px;
  padding-top: 10px;
  text-align: left;
  font-size: 17px;
  font-weight: 500;  
  margin-bottom: 20px;
  line-height: 26px;
}

.swal2-container.smartpi-promo-popup .swal2-header {
  align-items: flex-start;
}

.swal2-container.smartpi-promo-popup .swal2-title {
  margin: 0 15px;
  font-size: 22px;
}

.img-logo {
  width: 160px;
  margin-bottom: 30px;
}

.login-main-right h3.login-welcome {
  display: block;
  font-weight: bold;
  font-size: 28px;
  color: #393333;
  margin-bottom: 3px;
}

.login-main-right p {
}

p.login-intro-text {
  margin-bottom: 20px;
  width: 350px;
}

.login-main-right .select_code_country {
  border-color: #3D454D;
}

.login-whatsapp-button {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  margin-top: 10px;
  font-weight: bold;
}

.img-download {
  width: 250px;
  display: block;
  /* margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  align-content: center; */
}

.teacher_select_schedule {
  width: 200px;
}

.container_teacher_schedule {
  position: fixed;
}

.teacher_name_select_schedule {
  margin-top: 15px;
  font-size: 26px;
  
}

.link-download-app {
  width: 180px;
}

.link-download-app.playstore {
  width: 200px;
  margin-top: -4px;
}

.link-download-app img {
  width: 100%;
}

.img-phone {
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  align-content: center;
}

.login-input {
    height: 39px;
    min-height: auto;
    margin-left: 10px;
    font-size: 14px;
    border-color: #3D454D;
}

.row_image {
  align-content: center;
  text-align: center;
}

.img-register {
  width: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  align-content: center;
}
.container-center {
  margin-top: 70px;
  margin-bottom: 120px;
}

.center-position {
  text-align: center;
}

.register-div {
  background-color: lightblue;
}

.select_code_country {
  width: 300px;
}
.mobile-download {
  display: none;
}
.alert-form {
  /* height:5px; */
}
.footer_first {
  padding-top: 10px;
  padding-bottom: 0px;
  width: 100%;
  height: 250px;
  background-color: rgb(8, 33, 73);
  color: white;
}
.footer_first h4 {
  font-weight: 600;
}
.img-wa {
  width: 38px;
  margin-right: 5px;
}
.img-ig {
  width: 40px;
}
.list_footer {
  font-size: 15px;
}
.list_footer p {
  margin-bottom: 8px;
}

.list_footer a {
  text-decoration: none;
  color: #fff;
}

.list_footer a:hover {
  color: #0d6efd;
}
@media only screen and (max-width: 990px) {
  .mobile-download {
    display: block;
  }
  .setBackground {
    display: none;
  }
  .img-register {
    width: 200px;
  }
}
/* .setBackground{
  background: rgb(118, 72, 202);
    background-size: 50% auto;
    background-size: 100vh;
} */

.teacher-profile-container {
  display: flex;
  width: 100%;
  background: #F9FBFE;
  border: 1px solid #E3EAF1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px 50px;
  margin: 30px 0;
}

.teacher-profile-container .teacher-profile-avatar {
  width: 160px;
  height: 154px;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
}

.teacher-profile-container .teacher-profile-avatar img {
  width: 100%;
}

.teacher-profile-container .teacher-profile-btn {
  background: linear-gradient(134.93deg, #1187E6 0%, #4253DB 99.88%);
  border-radius: 10px;
  padding: 10px 13px;
  font-weight: 600;
  font-size: 14px;
  display: block;
  text-decoration: none;
  color: #fff;
  width: 116px;
  margin: 10px auto 0;
}

.teacher-profile-container .teacher-profile-btn:hover {
  background: #333;
}

.teacher-profile-container .teacher-profile-detail {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.teacher-profile-container .teacher-profile-detail .teacher-profile-title {
  font-size: 14px;
  color: #464B52;
}

.teacher-profile-container .teacher-profile-detail .teacher-profile-name {
  font-weight: bold;
  font-size: 18px;
  color: #25282B;
  margin-top: -3px;
}

.teacher-profile-container .teacher-profile-detail .teacher-profile-tag {
  color: #4253DB;
  font-weight: 600;
  font-size: 14px;
  margin-top: -3px;
}

.teacher-profile-container .teacher-profile-detail .teacher-profile-description {
  font-size: 14px;
  color: #272525;
  line-height: 22px;
  margin-top: 10px;
}

.select-session {
  padding: 10px
}

.video-profile-header {
  padding: 20px 10px 10px;
}

.video-profile-header .swal-close {
  top: 5px;
  right: 10px;
}

.video-profile-content iframe {
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
}

.banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.teacher-video-profile iframe {
  border-radius: 5px;
  margin-top: 20px;
}


.swal2-container.teacher-profile .swal2-modal {
  padding: 0;
  width: 640px;
}

.swal2-container.teacher-profile .swal2-content {
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
}

.swal2-container.teacher-profile .teacher-header {
  display: flex;
  align-items: center;
  background-color: #EDEEF3;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px 5px 0 0;
}

.swal2-container.teacher-profile .teacher-header .avatar {
  width: 77px;
  height: 77px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
}

.swal2-container.teacher-profile .teacher-header .avatar img {
  width: 100%;
}

.swal2-container.teacher-profile .teacher-header .teacher-info h2 {
  font-weight: 700;
  font-size: 18px;
  color: #25282B;
}


.swal2-container.teacher-profile .teacher-body-content {
  padding: 0 20px 40px;
  text-align: left;
}

.swal2-container.teacher-profile .teacher-body-content .biografi h3 {
  font-weight: 700;
  font-size: 16px;
  color: #25282B;
}

.swal2-container.teacher-profile .teacher-body-content iframe {
  border-radius: 5px;
  margin: 20px 20px;
}

.swal2-container.teacher-profile .teacher-body-content .list {
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 5px;
}

.swal2-container.teacher-profile .teacher-body-content .list .label {
  text-align: right;
  font-weight: 600;
  width: 180px;
  margin-right: 5px;
}

.swal2-container.teacher-profile .teacher-body-content .list .label span {
  display: inline-block;
  padding: 0 5px;
}

.swal2-container.teacher-profile .teacher-body-content .list .value {
  
}

.swal2-container.teacher-profile .teacher-body-content .list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.swal2-container.teacher-profile .teacher-body-content .list ul li {
  display: flex;
  align-items: center;
}

.swal2-container.teacher-profile .teacher-body-content .list ul li img {
  margin-right: 8px;
  position: relative;
  bottom: -1px;
}


.teacher-profile-detail .list {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 5px;
}

.teacher-profile-detail .list .label {
  text-align: right;
  font-weight: 600;
  width: 180px;
  margin-right: 5px;
}

.teacher-profile-detail .list .label span {
  display: inline-block;
  padding: 0 5px;
}

.teacher-profile-detail .list .value {
  
}

.teacher-profile-detail .list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.teacher-profile-detail .list ul li {
  display: flex;
  align-items: center;
}

.teacher-profile-detail .list ul li img {
  margin-right: 8px;
  position: relative;
  bottom: -1px;
}

.custom-datepicker input {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.swal-confirm-create .swal2-modal  {
  padding: 0;
}

.swal-confirm-create .swal2-popup .swal2-title  {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0;
}

.swal-confirm-create .swal2-html-container  {
  font-size: 16px;
  padding: 10px 20px;
  font-weight: normal;
  line-height: 26px;
}

.swal-confirm-create .swal2-html-container strong {
  color: #3185d6;
}

.swal-confirm-create .swal2-popup .swal2-actions {
  padding: 10px;
  background-color: #eff1f2;
}

.swal-confirm-create .swal2-popup .swal2-styled.swal2-confirm {
  font-weight: 600;
  font-size: 16px;
}

.student-home-top-section {
  width: 100%;
}

.student-home-top-section .main-banner {
  max-width: 70%;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 20px;
}

.student-home-top-section .main-banner img {
  width: 100%;
}

.student-home-top-section .split-card {
  display: flex;
}

.student-home-top-section .point-cointainer-card {
  max-width: 533px;
  width: 100%;
  padding: 25px;
  border-radius: 10px;
  background: linear-gradient(163deg, #FFF 0%, #E8F0FC 100%), #FFF;
  overflow: hidden;
  position: relative;
}

.student-home-top-section .point-cointainer-card .label {
  color: #323030;
  font-size: 16px;
  letter-spacing: -0.36px;
}

.student-home-top-section .point-cointainer-card .big {
  margin-bottom: 20px;
}

.student-home-top-section .point-cointainer-card .big .label {
  font-size: 18px;
}

.student-home-top-section .point-cointainer-card .big h2 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.96px;
}

.student-home-top-section .point-cointainer-card .item {
  margin-bottom: 20px;
  position: relative;
  width: max-content;
}

.student-home-top-section .point-cointainer-card .item h2 {
  font-size: 20px;
  font-weight: 700;
}

.student-home-top-section .point-cointainer-card .item h2.link {
  font-size: 16px;
}

.student-home-top-section .point-cointainer-card .btn-copy-small {
  border: none;
  position: absolute;
  right: -24px;
  bottom: -2px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #636363;
}

.student-home-top-section .point-cointainer-card .btn-copy-small:hover {
  opacity: 0.8;
}

.student-home-top-section .point-cointainer-card .item:has(.link) .btn-copy-small {
  right: -28px;
  bottom: -4px;
}


.student-home-top-section .point-cointainer-card button.big-copy-code-button {
  border-radius: 8px 0px 0px 0px;
  background: #2971DF;
  position: absolute;
  padding: 22px;
  color: #fff;
  font-weight: 500;
  border: none;
  right: 0;
  bottom: 0;
}

.student-home-top-section .point-cointainer-card button.big-copy-code-button:hover {
  background: #1b59b6;
}

.student-home-top-section .point-cointainer-card .img-ornament {
  position: absolute;
  right: 40px;
  top: 24px;
  width: 160px;
}

.student-home-top-section .banner-card {
  position: relative;
  flex: 1;
  margin-left: 20px;
}

.student-home-top-section .banner-card .title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 18px;
  padding: 0 10px;
}

.student-home-top-section .banner-card .title-container h2 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
}

.student-home-top-section .banner-card .title-container p {
  color: #383636;
  font-size: 16px;
  letter-spacing: -0.32px;
  max-width: 340px;
}

.student-home-top-section .banner-card .title-container .icon {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1280px) {
  .student-home-top-section .point-cointainer-card {
    max-width: 380px;
  }
}
